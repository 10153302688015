"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCode = void 0;
var ErrorCode;
(function (ErrorCode) {
    ErrorCode["ContainerBlocked"] = "ContainerBlocked";
    ErrorCode["ErrorFetchingContainer"] = "ErrorFetchingContainer";
    ErrorCode["LibBlockSurvey"] = "LibBlockSurvey";
    ErrorCode["NoDashboardOrContainer"] = "NoDashboardOrContainer";
    ErrorCode["NoSurveyPermission"] = "NoSurveyPermission";
    ErrorCode["NoViewResultsPermission"] = "NoViewResultsPermission";
    ErrorCode["SurveyIsDeleted"] = "SurveyIsDeleted";
    ErrorCode["UnknownError"] = "UnknownError";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));
