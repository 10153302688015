"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var widgets_1 = require("@shared/constants/widgets");
var angular_1 = require("angular");
var SERVICE_NAME = 'pr.widgetLayoutUtils';
/**
 * Pick a fallback that is as large as possible while remaining
 * less than each of our supported number of page rows/columns.
 */
var PAGE_SPAN_FALLBACK = 60;
var WidgetLayoutUtils = /** @class */ (function () {
    function WidgetLayoutUtils(loggerService, pageLayoutService) {
        this.pageLayoutService = pageLayoutService;
        this.logger = loggerService.getLoggerManager(SERVICE_NAME);
    }
    /**
     * There is a longstanding bug where widget layout properties are somehow set
     * to invalid values (e.g. undefined, NaN). We don't know the root cause
     * but we should validate widget layouts to exit this state. Use intelligent
     * fallback so that widgets do not have extremely tiny widths or heights.
     */
    WidgetLayoutUtils.prototype.getValidWidgetLayout = function (widget) {
        var _a = widget.layout || {}, originalRow = _a.row, originalCol = _a.col, originalRowSpan = _a.rowSpan, originalColSpan = _a.colSpan;
        var pageRows = this.pageLayoutService.getRows();
        var pageCols = this.pageLayoutService.getCols();
        var validPageRows = this.getValidPageSpan(widget, 'pageRows', pageRows);
        var validPageCols = this.getValidPageSpan(widget, 'pageCols', pageCols);
        var rowSpan = this.getValidWidgetRowSpan(widget, originalRowSpan, validPageRows, validPageCols);
        var colSpan = this.getValidWidgetColSpan(widget, originalColSpan, validPageCols);
        var row = this.getValidWidgetRow(widget, originalRow);
        var col = this.getValidWidgetCol(widget, originalCol, validPageCols, colSpan);
        return __assign(__assign({}, widget.layout), { row: row, col: col, rowSpan: rowSpan, colSpan: colSpan });
    };
    /**
     * Page cols and page rows should be positive integers
     */
    WidgetLayoutUtils.prototype.getValidPageSpan = function (widget, name, current) {
        return this.getValidNumber({
            widget: widget,
            name: name,
            current: current,
            fallback: PAGE_SPAN_FALLBACK,
            lowerBound: 1,
            upperBound: Infinity
        });
    };
    /**
     * ColSpan should be a positive integer that is no wider than a page
     */
    WidgetLayoutUtils.prototype.getValidWidgetColSpan = function (widget, currentColSpan, validPageCols) {
        return this.getValidNumber({
            widget: widget,
            name: 'colSpan',
            current: currentColSpan,
            fallback: validPageCols,
            lowerBound: 1,
            upperBound: validPageCols
        });
    };
    /**
     * RowSpan should be a positive integer that is no taller than a page,
     * unless the widget is chunked in which case it can have an unbounded height.
     */
    WidgetLayoutUtils.prototype.getValidWidgetRowSpan = function (widget, currentRowSpan, validPageRows, validPageCols) {
        var lowerBound = widget._type === widgets_1.PR_WIDGETS.PAGE_BREAK ? 0 : 1;
        return this.getValidNumber({
            widget: widget,
            lowerBound: lowerBound,
            name: 'rowSpan',
            current: currentRowSpan,
            // This mimics the row span we use for new widgets, see getDefaultLayout() in body.js
            fallback: validPageCols / 3,
            upperBound: widget.isChunked ? Infinity : validPageRows
        });
    };
    /**
     * Col should be a non-negative integer.
     * Should be adjusted so that the widget fits completely on the page.
     */
    WidgetLayoutUtils.prototype.getValidWidgetCol = function (widget, currentCol, validPageCols, validColSpan) {
        return this.getValidNumber({
            widget: widget,
            name: 'col',
            current: currentCol,
            fallback: 0,
            lowerBound: 0,
            // Adjust the column so the widget doesn't cross the right edge of the page
            upperBound: validPageCols - validColSpan
        });
    };
    /**
     * Row should be a non-negative integer.
     * We do not need to adjust to fit the widget on the page. If the widget does
     * not fit on the current page, then the layout engine will naturally push the
     * widget to the next page.
     */
    WidgetLayoutUtils.prototype.getValidWidgetRow = function (widget, currentRow) {
        return this.getValidNumber({
            widget: widget,
            name: 'row',
            current: currentRow,
            fallback: 0,
            lowerBound: 0,
            // Set to Infinity because `row` is indexed to the report, not to the page.
            upperBound: Infinity
        });
    };
    WidgetLayoutUtils.prototype.getValidNumber = function (options) {
        var current = options.current, fallback = options.fallback, lowerBound = options.lowerBound, upperBound = options.upperBound;
        var numericValue = (typeof current === 'number' && !isNaN(current)) ? current : fallback;
        if (numericValue !== current) {
            this.logWarning('Current value was not a valid number', options);
        }
        // All layout values must be integers because containersAPI will reject any decimals
        var integerValue = Math.floor(numericValue);
        if (integerValue !== numericValue) {
            this.logWarning('Current value was not an integer', options);
        }
        if (integerValue < 0) {
            this.logWarning('Current value was negative', options);
        }
        // Don't log out of bounds values because this is expected when changing the page size
        return boundNumber(integerValue, lowerBound, upperBound);
    };
    WidgetLayoutUtils.prototype.logWarning = function (message, options) {
        this.logger.warn({ message: message, options: options });
    };
    return WidgetLayoutUtils;
}());
exports.default = WidgetLayoutUtils;
function boundNumber(value, lowerBound, upperBound) {
    var withLowerBoundApplied = Math.max(value, lowerBound);
    return Math.min(withLowerBoundApplied, upperBound);
}
(function (angularModule) {
    angularModule.service(SERVICE_NAME, [
        'pr.loggerService',
        'prPageLayoutService',
        WidgetLayoutUtils
    ]);
})(angular_1.module('qualtrics.pr'));
